.nav-main {
    // z-index: 2;

    // Container menu
    &__container {
        @include flex($justifyContent: space-between, $alignItems: center);
        padding: 0;
    }

    // Main menu
    .menu {
        @include flex($alignItems: center, $wrap: wrap);

        &__item {
            padding: 0 32.5px;

            &.menu-item-has-children.js-open-submenu {
                .submenu {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: inherit;
                }

                .menu__link {
                    &:before {
                        height: 8px;
                    }
                }

                &:last-child {
                    .menu__link {
                        color: $color-white;

                        &:after {
                            background-color: $color-main;
                        }

                        &:before {
                            background-color: $color-third;
                            height: 3px;
                        }
                    }
                }
            }
    
            &:last-child {
                padding: 0;

                .menu__link {
                    position: relative;
                    z-index: 1;
                    margin: 0 0 0 160px;
            
                    &:before {
                        @include position(absolute, $bottom: 18px, $left: 0);
                        @include size(170px, 3px);
                        content: "";
                        background-color: $color-white;
                        z-index: 0;
                        transition: all $duration ease-in-out;
                    }

                    &:after {
                        @include position(absolute, $top: -20px, $right: -40px);
                        @include size(250px, 170px);
                        content: "";
                        background-color: $color-third;
                        z-index: -1;
                        transition: background-color $duration ease-in-out;
                    }

                    &:hover, &:focus {
                        color: $color-white;

                        &:after {
                            background-color: $color-main;
                        }

                        &:before {
                            background-color: $color-third;
                            height: 3px;
                        }
                    }
                }
            }
        }   
        
        &__link {
            position: relative;
            @include flex($alignItems: center);
            gap: 5px;
            height: 80px;
            color: $color-dark;
            text-transform: uppercase;
            font-family: $font-family--heading;
            font-size: toRem(17);
            font-weight: 800;
            line-height: toRem(24);
            letter-spacing: 0.06em;
            z-index: 1;
            transition: {
                property: background-color, color;
                duration: $duration;
                timing-function: $timing;
            }

            span {
                font-weight: 500;
            }

            &:before {
                @include position(absolute, $left: -3px, $bottom: 28px);
                @include size(calc(100% + 10px), 0);
                content: "";
                background-color: $color-third;
                z-index: -1;
                transition: all $duration ease-in-out;
            }
    
            &:hover, &:focus {
                &:before {
                    height: 8px;
                }
            }
        }
    }

    // Sub menu
    .submenu {
        @include flex($justifyContent: center);
        @include position(absolute, $top: 140px, $right: 0);
        z-index: 2;
        width: 100%;
        padding: 32px 15px 50px;
        background-color: rgba($color-second, 0.98);
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        transition: {
            property: visibility, opacity, pointer-events;
            duration: $duration;
            timing-function: $timing;
        }

        &__nav {
            width: 100%;
            column-count: 3;
            column-gap: 30px;
            padding-right: 80px;
        }

        &__item {
            display: inline-flex;
            width: 100%;
            margin: -1px; // Fix space beetween inline-flex
            padding: 0;
            line-height: initial;
        }

        &__link {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 1px; // Fix space beetween inline-flex
            padding: 15px 30px 15px 0;
            border-bottom: 1px solid rgba($color-white, 0.5);
            color: $color-white;
            font-size: toRem(16);
            font-weight: 700;
            line-height: toRem(22);

            &::before {
                content: "";
                display: inherit;
                position: absolute;
                bottom: -1px;
                width: 0;
                height: 2px;
                background-color: $color-third;
                transition: width $duration $timing;
            }

            &::after {
                content: '\f345';
                font: normal 20px/1 dashicons;
                position: absolute;
                right: 0;
                color: rgba($color-white, 0.5);
                transition: color $duration $timing;
            }

            &:hover, &:focus {
                &::before {
                    width: 100%;
                }

                &::after {
                    color: $color-white;
                }
            }

            &--grandchild {
                display: flex;
                padding: 17px 10px 17px 15px;
                border-bottom: 0;
                font-size: $font-size--text-small;
                font-weight: $font-weight;
                text-transform: initial;
                line-height: initial;

                &::before {
                    display: none;
                }
            }
        }
        
        &__close {
            @include absolute($top: 15px);
            @extend %SpaceFull-to-Container--right;
            margin: 0;
            padding: 0;
            background-color: $color-bg--transparent;

            &__icon {
                display: block;
                @include size(30px);
                // margin-right: 7px;
                background-color: $color-white;
                border: $btn-border-width $btn-border-style $color-white;
                border-radius: $border-radius--round;
                fill: $color-dark;
            }

            &__mobile {
                display: none;
            }

            svg {
                transition: {
                    property: background-color, fill;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            &:hover, &:focus {
                .submenu__close__icon {
                    background-color: $color-bg--transparent;
                    fill: $color-white;
                }
            }
        }
    }
    
    // For responsive
    &__button, &__icon, &__close {
        display: none;
    }
}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    .nav-main {
        .menu {
            &__item {
                padding: 0 19.5px;

                &:last-child .menu__link {
                    margin: 0 0 0 132px;

                    &:before {
                        width: 152px;
                        bottom: 22px;
                    }

                    &:after {
                        @include size(192px, 150px);
                        right: -20px;
                        top: -15px;
                    }
                }
            }

            &__link {
                font-size: toRem(15);
                line-height: toRem(24);
            }
        }

        .submenu {
            top: 120px;
            padding: 32px 15px 40px;

            &__link {
                font-size: toRem(14);
                padding: 10px 30px 10px 0;
            }
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    // if menu open (no scroll on body)
    body.js-open-menu {
        overflow: hidden;
    }    

    .nav-main {
        position: relative;

        // If menu open
        &.js-open-menu {
            
            // Container menu
            .nav-main__container {
                left: 0;
            }
        }

        > .container {
            padding: 0;
            margin: 0;
            max-width: unset;
            // position: relative;

            
        }

        // Container menu
        &__container {
            position: fixed;
            top: 0;
            left: -100vw;
            right: 100%;
            z-index: 990;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            row-gap: 15px;
            max-width: inherit;
            width: 100%;
            height: 100vh;
            background-color: $color-second;
            padding: 40px 0;
            transition: left $duration $timing;
        }
        
        // Burger menu responsive - Open menu
        &__button {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            column-gap: 12px;
            min-height: 69px;
            margin: 0 2px 0 0;
            padding: 0;
            background-color: transparent;
            border: 0;
            border-radius: 0;
            font-family: $font-family--heading;
            font-size: toRem(17);
            font-weight: 800; 
            line-height: toRem(24);
            letter-spacing: 0.6px;
            color: $color-dark;
            text-transform: uppercase;
            cursor: pointer;
            z-index: 1;

            &:before {
                @include position(absolute, $top: -31px, $right: -52px);
                @include size(195px, 120px);
                content: "";
                background-color: $color-third;
                z-index: -1;
                transition: background-color $duration ease-in-out;
            }

            &:hover, &:focus {
                color: $color-white;

                &:before {
                    background-color: $color-main;
                }

                .nav-main__icon {
                    background-color: $color-white;

                    &:before, 
                    &:after {
                        background-color: $color-white;
                    }
                }
            }
        }

        // Button icon (Burger menu responsive - Open menu)
        &__icon {
            position: relative;
            top: 0;
            display: block;
            width: 15px;
            height: 2px;
            background-color: $color-dark;
            transition: background-color $duration ease-in-out;

            &:before, 
            &:after {
                content: '';
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                background-color: $color-dark;
                transition: background-color $duration ease-in-out;
            }

            &:before {
                top: 5px;
            }

            &:after {
                top: -5px;
            } 
        }

        // Burger menu responsive - Close menu
        &__close {
            position: relative;
            z-index: 10;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            min-height: 40px;
            margin: 40px 40px 0 auto;
            padding: 10px;
            background-color: $color-third;
            border: 0;
            border-radius: $border-radius--round;

            svg {
                width: 12px;
                height: 12px;
                margin: 0;
                fill: $color-dark;
            }

            &:hover,
            &:focus {
                background-color: $color-main;

                svg {
                    fill: $color-white;
                }
            }
        }

        // Main menu
        .menu {
            flex-direction: column;
            flex-wrap: nowrap;
            overflow-y: auto;
            width: 100%;
            padding: 10px 40px 0;

            &__item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%!important;
    
                &.menu-item-has-children {

                    // If sub menu open
                    &.js-open-submenu {   
                        .submenu {
                            right: 0;
                        }
    
                        .menu__link {
                            background-color: $color-bg--transparent;
                            color: $color-white;
                        }
                    }
                }

                &:last-child {
                    padding: 0 19.5px;

                    .menu__link {
                        margin: 0;
    
                        &:before {
                            @include position(absolute, $left: -3px, $bottom: 20px);
                            @include size(calc(100% + 10px), 8px);
                            content: "";
                            background-color: $color-main;
                            z-index: -1;
                            transition: all $duration ease-in-out;
                        }
                    }
                }
    
                &:hover, &:focus {   
                    .menu__link {
                        background-color: $color-bg--transparent;
                        color: $color-white;
                    }
                }
            }

            &__link {
                justify-content: flex-start !important;
                max-width: inherit;
                width: fit-content;
                height: auto;
                padding: 20px 0;
                color: $color-white;
                transition: none;
                font-size: toRem(17);

                &::after {
                    display: none;
                }

                &:before {
                    height: 8px;
                    background-color: $color-main;
                    bottom: 20px;
                }

                &:hover, &:focus {
                    background-color: $color-bg--transparent;
                    color: $color-white;
                }
            }
        }

        // Sub menu
        .submenu {
            left: inherit;
            top: 0;
            right: 100%;
            z-index: 2;
            @include flex($direction: column, $justifyContent: flex-start);
            row-gap: 30px;
            @include size(100vw, 100vh);
            padding: 40px 0 60px 0;
            background-color: $color-second;
            transition: {
                property: visibility, pointer-events, right;
                duration: $duration;
                timing-function: $timing;
            }
            opacity: 1;

            &::after {
                display: none;
            }

            // Burger menu responsive - Close submenu
            &__close {
                position: static;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                width: 40px;
                height: 40px;
                margin: 0 0 0 40px;
                padding: 0;
                background-color: $color-third;
                border: 0;
                border-radius: $border-radius--round;
                order: 1;

                svg {
                    stroke: $color-dark;
                }

                &:hover,
                &:focus {
                    background-color: $color-main;

                    svg {
                        fill: none;
                        stroke: $color-white;
                    }
                }

                &__mobile {
                    display: block;
                    width: 40px;
                    height: 40px;
                    fill: none;
                    stroke: $color-white;
                    stroke-width: 2px;
                }

                &__icon, span {
                    display: none;
                }
            }

            &__nav {
                display: flex;
                flex-direction: column;
                max-width: 100%;
                padding: 10px 40px;
                overflow-y: auto;
                order: 2;
            }

            &__item {
                margin: auto; 
            }

            &__link {
                color: $color-white;
                padding: 14px 30px 14px 0;

                // &::after {
                //     display: none;
                // }
            }
        }
    }

    // Admin 
    .admin-bar {
        .nav-main {
            &__container, .submenu {
                padding-top: 70px;
            }
        }
    }


}

// 640
@media screen and (max-width: $small) {
    .nav-main {
        &__button {
            flex-direction: column;
            justify-content: flex-start;
            font-size: toRem(8);
            margin: 13px 0 0;
            padding: 0;
            gap: 5px;
            min-height: unset;

            &:before {
                width: 120px;
                top: -40px;
            }
        }

        &__icon {
            width: 24px;

            &:before {
                top: 8px;
            }

            &:after {
                top: -8px;
            }
        }
    }
}