.cover {
    position: relative;
    z-index: 1;
    margin-bottom: 50px;

    &__image-wrapper {
        position: relative;
        margin: 0 0 -20px;

        &:before {
            @include position(absolute, $left: 0, $bottom: 0);
            @include size(100%, 110px);
            content: "";
            background-color: $color-second;
            z-index: -1;
        }

        > .container {
            width: 100%;
            height: 410px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
            }
        }
    }

    &__informations {
        background-color: $color-second;
        padding: 50px 0 67px;
    }

    &__title {
        color: $color-white;
        margin: 0;
    }

    &__lead-wrapper {
        p {
            margin: 30px 0 0;
            font-weight: 500;
            font-size: toRem(18);
            line-height: toRem(24);
            color: $color-intro;
        }
    }

    &__intro {
        color: $color-text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .cover {
        &__image-wrapper {
            &:before {
                height: 100px;
            }
    
            > .container {
                height: 380px;
            }
        }

        &__title {
            font-size: toRem(45px);
            line-height: toRem(50);
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .cover {
        &__image-wrapper {
            &:before {
                height: 100%;
            }
    
            > .container {
                height: 290px;
                max-width: unset;
                padding: 0;
                margin: 0;
            }
        }

        &__title {
            font-size: toRem(40px);
            line-height: toRem(45);
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .cover {
        &__image-wrapper {
            > .container {
                height: 200px;
            }
        }

        &__informations {
            padding: 45px 0 55px;
        }

        &__title {
            font-size: toRem(35px);
            line-height: toRem(40);
        }
    }
}
