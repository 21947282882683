@keyframes slideDown {
    from {
        top: -100px;
    }
    to {
        top: 0;
    }
}

@keyframes slideUp {
    from {
        top: 0;
    }
    to {
        top: -100px;
    }
}

.header {
    height: 140px;

    &__wrapper {
        background-color: $color-white;
        height: 100%;

        &.fixed {
            @include position(fixed, $top: 0);
            @include size(100%, 90px);
            z-index: 12;
            box-shadow: 1px 1px 15px 0px rgba(27, 35, 54, 0.15);
            animation: slideDown $duration ease-in-out forwards;
    
            .header {
                &__link svg {
                    @include size(160px, 60px);
                }
    
                &__content {
                    margin: 0;
                }
            }
    
            .nav-main {
                .menu__item {
                    padding: 0 30.5px;
        
                    &:last-child .menu__link {
                        margin: 0 10px 0 150px;
                        
                        &:after {
                            height: 90px;
                            top: -5px;
                        }
                    }
                }
    
                .submenu {
                    top: 90px;
                    // box-shadow: 1px 1px 15px 0px rgba(27, 35, 54, 0.5);
                }
            }
    
            .tools {
                left: calc(50% + 213px);
                top: 25px;
            }
        }
    
        &.slide-up {
            animation: slideUp $duration ease-in-out forwards;
        }
    }
    
    &__container {
        @include flex($justifyContent: space-between, $alignItems: center);
        height: 100%;
    }

    &__title {
        font-size: 0;
        line-height: 0;
        @include size(0);
        display: flex;
    }

    &__content {
        @include flex($alignItems: center);
        margin: 40px 0 0;
    }
}

//======================================================================================================
// Tools
//======================================================================================================
.tools {
    @include position(absolute, $top: 70px, $left: calc(50% + 263px));
    @include flex($alignItems: center);
    gap: 10px;

    &__item {
        @include size(40px);
        background-color: transparent;
        border-color: transparent;
        padding: 0;
        margin: 0;

        svg {
            fill: $color-dark;
            @include size(100%);
            transition: fill $duration ease-in-out;
        }

        &:hover, &:focus {
            background-color: transparent;
            border-color: transparent;
            
            svg {
                fill: $color-main;
            }
        }

        &--search--disabled {
            pointer-events: none;
            opacity: 0.35;
        }
    }

    #accessconfig {
        height: 40px;
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .header {
        height: 120px;

        &__wrapper {
            &.fixed {
                .nav-main {
                    .menu__item {
                        padding: 0 15px;
            
                        &:last-child .menu__link {
                            margin: 0 5px 0 120px;

                            &:after {
                                right: -20px;
                            }
                        }
                    }
                }
        
                .tools {
                    left: calc(50% + 170px);
                    top: 25px;
                }
            }
        }

        &__link svg {
            @include size(190px, 72px);
        }

        &__content {
            margin: 30px 0 0;
        }
    }

    .tools {
        left: calc(50% + 193px);
        top: 55px;
    }
}


// 960
@media screen and (max-width: $medium) {
    .header {
        &__content {
            flex-direction: row-reverse;
            margin: 10px 0 0;
            gap: 80px;
        }
    }

    .tools {
        @include position(relative, $top: unset, $left: unset);
    }
}


// 640
@media screen and (max-width: $small) {
    .header {
        height: 80px;
        
        &__link svg {
            @include size(130px, 50px);
        }

        &__content {
            gap: 60px;
        }
    }

    .tools {
        gap: 5px;

        &__item {
            @include size(35px);
        }

        #accessconfig {
            height: 35px;
        }
    }
}
