.footer {
    background-color: $color-second;
    color: $color-white;
    
    &__container {
        position: relative;

        &:before {
            @include position(absolute, $top: -20px, $left: calc(50% + -585px));
            @include size(240px, calc(100% + 20px));
            content: "";
            background-color: $color-main;
        }
    }

    &__content {
        position: relative;
        z-index: 1;
        padding: 70px 0 60px;
        display: flex;
        position: relative;

        &:before {
            @include position(absolute, $top: 25px, $left: 2px);
            @include size(25px, 35px);
            content: "";
            background-color: $color-third;
        }
    }

    &__logo {
        margin-right: 125px;
        
        svg {
            @include size(170px, 240px);
            fill: $color-white;
            margin: 5px 0 0 50px;
        }
    }

    &__coordonnees {
        width: 240px;
        margin-right: 100px;

        .footer__text {
            margin: 15px 0 20px;
        }
    }

    &__horaires {
        width: 425px;
    }

    &__title {
        font-family: $font-family--heading;
        font-size: toRem(22);
        font-weight: 700;
        line-height: toRem(24);
    }   

    &__text {
        font-size: toRem(16);
        font-weight: 400;
        line-height: toRem(22);
        margin: 15px 0 0;
    }

    &__phone {
        text-decoration: underline;
        text-decoration-color: transparent;
        transition: all $duration ease-in-out;

        &:hover, &:focus {
            text-decoration-color: $color-white;
            color: $color-white;
        }
    }

    &__contact {
        @extend %button;
        color: $color-white;
        border-color: $color-third!important;
        padding: 0!important;
        justify-content: flex-start;
        width: 208px;
        margin: 45px 0 0 !important;

        svg {
            fill: $color-third;
        }

        &:hover, &:focus {
            color: $color-dark;

            &:before {
                background-color: $color-third;
            }

            svg {
                fill: $color-dark;
            }
        }
    }

    &__buttons {
        @include flex($alignItems: center);
        gap: 20px;
        margin: 30px 0 0;
    }

    &__button {
        @include flex($alignItems: center, $justifyContent: center);
        background-color: $color-main;
        @include size(40px);
        border-radius: 50%;
        transition: background-color $duration ease-in-out;

        svg {
            fill: $color-white;
            @include size(30px);
            transition: fill $duration ease-in-out;
        }

        &:hover, &:focus {
            background-color: $color-third;

            svg {
                fill: $color-dark;
            }
        }
    }
    
    &__newsletter {
        @include flex($direction: row, $alignItems: center, $justifyContent: center);
        gap: 10px;
        margin-left: 20px;
        color: $color-white;
        font-family: $font-family--heading;

        svg {
            @include size(40px);
            padding: 5px;
            fill: $color-text;
            background-color: $color-fourth;
            border-radius: $border-radius--round;
            transition: {
                property: background-color, fill;
                duration: $duration;
                timing-function: ease-in-out;
            }
        }

        span {
            font-size: toRem(16);
            font-weight: $font-weight-semibold;
        }

        &:hover, &:focus {
            color: $color-white;

            svg {
            fill: $color-white;
            background-color: $color-main;
            }
        }
    }

    &__menu {
        padding: 30px 0;
        background-color: $color-white;

        &__list {
            @include flex($wrap: wrap, $alignItems: center, $justifyContent: flex-end);
            gap: 38.4px;
            width: 100%;
        }

        &__link {
            color: $color-gray;
            font-size: toRem(14);
            font-weight: 400;
            line-height: toRem(20);

            &:hover, &:focus {
                // color: $color-text;
                // text-decoration: underline;
            }
        } 
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .footer {
        &__container {
            &:before {
                left: calc(50% - 470px);
                width: 200px;
            }
        }
    
        &__content {
            padding: 70px 0 65px;

            &:before {
                left: -3px;
            }
        }
    
        &__logo {
            margin-right: 75px;
            
            svg {
                @include size(150px, 210px);
                margin: 10px 0 0 35px;
            }
        }
    
        &__coordonnees {
            width: 240px;
            margin-right: 50px;
        }
    
        &__horaires {
            width: 400px;
        }
    
        &__menu {
            padding: 25px 0;
    
            &__list {
                gap: 26.8px;
            }
    
            &__link {
                font-size: toRem(12);
            } 
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .footer {
        &__container {
            &:before {
                display: none;
            }
        }
    
        &__content {
            flex-direction: column;
            align-items: center;
            padding: 0 0 55px;
            gap: 30px;

            &:before {
                display: none;
            }
        }
    
        &__logo {
            @include size(150px, 200px);
            background-color: $color-main;
            @include flex($alignItems: center, $justifyContent: center);
            margin: -20px 0 0;
            position: relative;

            &:before {
                @include position(absolute, $top: 13px, $left: -13px);
                @include size(25px, 35px);
                content: "";
                background-color: $color-third;
            }
            
            svg {
                @include size(100px, 140px);
                margin: 0;
            }
        }
    
        &__coordonnees {
            @include flex($alignItems: center, $direction: column);
            margin-right: 0;
        }

        &__title {
            text-align: center;
        }

        &__text {
            text-align: center;
        }

        &__contact {
            margin: 30px 0 0 !important;
        }

        &__buttons {
            justify-content: center;
        }
    
        &__menu__list {
            justify-content: center;
            gap: 18px 29px;
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .footer {
        &__horaires {
            width: 300px;
        }

        &__buttons {
            flex-wrap: wrap;
        }

        &__newsletter {
            margin-left: 0;
        }
    }
}
