//======================================================================================================
// SlideShow
//======================================================================================================
.alu {
    width: 100%;
    position: relative;

    &--video {
        z-index: 1;
        img {
            display: none;
        }
    }

    &__container {
        position: relative;
    }

    .container--pagination {
        @include absolute(0, 0, 0, 0);
        height: 100%;
    }

    .swiper-container {
        &--media {
            .alu__item {
                overflow: hidden;
            }
        }

        &--content {
            @include position(absolute, $top: 0, $left: 50%);
            width: calc(100% - 30px);
            transform: translateX(-50%);
            overflow: visible;
        }        
    }

    &__item {
        @include size(1170px!important, 480px!important);
        display: flex;
        position: relative;
    }

    &__media {
        @include size(100%);
        background-color: $color-bg--image;
        overflow: hidden;

        img,
        video {
            @include size(100%);
            object-fit: cover;
            object-position: top;
        }
    }

    &__content {
        @include position(absolute, $left: -40px, $bottom: 115px);
        @include flex($direction: column);
        gap: 25px;
        max-width: 345px;
        z-index: 2;
        padding: 45px 40px;
        background-color: $color-main;
    }

    &__title {
        font-family: $font-family--heading;
        font-size: toRem(24);
        line-height: toRem(25);
        font-weight: 700;
        color: $color-white;
        margin: 0;
        padding: 0;
        position: relative;

        &:before {
            @include position(absolute, $left: 0, $bottom: -15px);
            @include size(35px, 3px);
            content: "";
            background-color: $color-third;
            z-index: 0;
            transition: all $duration ease-in-out;
        }
    }

    &__text {
        font-size: toRem(16);
        line-height: toRem(22);
        font-weight: 400;
        color: $color-white;
    }

    &__pagination {
        @include absolute($bottom: 40px, $left: 70px);
        @include flex($alignItems: center, $justifyContent: center);
        z-index: 2;
        width: auto;

        .swiper-pagination-bullet {
            @include size(14px);
            margin: 0 14px 0 0;
            background-color: $color-white;
            border: 2px solid $color-white;
            border-radius: 50px;
            opacity: 1;
            transition: {
                property: width, background-color;
                duration: $duration;
                timing-function: $timing;
            }

            &:hover, &:focus {
                background-color: transparent;
            }

            &-active {
                background-color: transparent;
            }
        }
    }
}

//======================================================================================================
// Accès rapides
//======================================================================================================

.access {
    position: relative;
    z-index: 1;

    &__container {
        @include flex($alignItems: center, $justifyContent: space-between);
    }

    &__blocs {
        @include flex($justifyContent: space-between, $alignItems: flex-start);
        gap: 45px;
        padding: 27px 43px;
        background-color: $color-white;
        box-shadow: 0px 10px 50px 0px rgba(27, 35, 54, 0.1);
        margin: -20px auto 0;
    }

    &__bloc {
        @include flex($direction: column, $alignItems: center, $justifyContent: center);
        width: 120px;

        p {
            font-family: $font-family--heading;
            font-size: toRem(16);
            font-weight: 600;
            line-height: toRem(19.5);
            color: $color-dark;
            position: relative;
            z-index: 1;

            &:before {
                @include position(absolute, $left: -3px, $bottom: 0);
                @include size(calc(100% + 7px), 0);
                content: "";
                background-color: $color-third;
                z-index: -1;
                transition: all $duration ease-in-out;
            }
        }

        &:hover, &:focus {
            p:before {
                height: 8px;
            }

            .access__picto path {
                &[fill="#3B8081"] {
                    fill: #F6CA3E;
                }
            }
        }
    }

    &__picto {
        @include size(90px);
        transition: background-color $duration ease-in-out;

        path {
            transition: fill $duration ease-in-out;
        }
    }

    &__exit {
        @include size(26px);
        fill: $color-dark;
    }
}

//======================================================================================================
// Actualités
//======================================================================================================

.news {
    position: relative;
    margin: 115px 0 60px;
    z-index: 1;

    &:before {
        @include position(absolute, $left: 0, $bottom: -60px);
        @include size(100%, 640px);
        content: "";
        background-color: $color-second;
    }

    &__container {
        @include flex($direction: column, $alignItems: flex-end);
        position: relative;
    }

    &__header {
        @include flex($direction: column);
        width: 100%;
        gap: 5px;
    }

    &__title {
        @extend %titleHome;
    }

    &__subtitle {
        @extend %subTitle;
    }

    &__content {
        @include flex($direction: column);
        gap: 50px;
        margin-top: 38px;
    }

    &__items {
        @include flex($justifyContent: flex-end);
        gap: 60px;
    }

    &__link {
        &--big {
            @include flex($alignItems: center);

            &:hover, &:focus {
                .news__titlep--big::before {
                    width: 75px;
                }
            }
        }

        &--small {
            width: 350px;
            @include flex($direction: column, $alignItems: flex-start);

            &:hover, &:focus {
                .news__titlep--small::before {
                    width: 55px;
                }
            }
        }

        &:hover, &:focus {
            .news__image img {
                transform: scale(1.05);
            }
        }
    }

    &__image {
        overflow: hidden;

        &--big {
            @include size(670px, 370px);
            min-width: 670px;
        }

        &--small {
            @include size(100%, 200px);
            position: relative;

            &:before {
                @include position(absolute, $left: 0, $bottom: 0);
                @include size(325px, 25px);
                content: "";
                background-color: $color-second;
                z-index: 1;
            }
        }

        img {
            @include size(100%);
            object-fit: cover;
            transform: scale(1);
            transition: $duration;
        }
    }

    .notimg {
        background: url('/wp-content/themes/noyau/assets/images/icon-post.png') no-repeat $color-gray;
        background-position: center;

        &--big {
            @include size(670px, 370px);
            min-width: 670px;
        }

        &--small {
            @include size(100%, 200px);
            position: relative;

            &:before {
                @include position(absolute, $left: 0, $bottom: 0);
                @include size(325px, 25px);
                content: "";
                background-color: $color-second;
                z-index: 1;
            }
        }
    }

    &__infos {
        @include flex($direction: column);

        &--big {
            background-color: $color-main;
            padding: 50px;
            gap: 40px;
            margin-left: -30px;
            position: relative;

            &:before {
                @include position(absolute, $bottom: -18px, $right: 23px);
                @include size(25px, 35px);
                content: "";
                background-color: $color-third;
            }
        }

        &--small {
            margin: -10px 0 0;
            gap: 25px;
            position: relative;
            z-index: 1;
        }
    }

    &__titlep {
        font-family: $font-family--heading;
        font-weight: 700;
        margin: 0;
        padding: 0;
        color: $color-white;
        position: relative;

        &:before {
            @include position(absolute, $left: 0);
            height: 3px;
            content: "";
            background-color: $color-third;
            transition: width $duration ease-in-out;
        }

        &--big {
            font-size: toRem(34);
            line-height: toRem(38);

            &:before {
                bottom: -22px;
                width: 60px;
            }
        }

        &--small {
            font-size: toRem(21);
            line-height: toRem(26);
            max-width: 300px;
            transition: color $duration ease-in-out;

            &:before {
                bottom: -15px;
                width: 40px;
            }
        }
    }

    &__intro {
        font-weight: 400;

        &--big {
            font-size: toRem(18);
            line-height: toRem(24); 
            color: $color-white;
        }

        &--small {
            font-size: toRem(16);
            line-height: toRem(22); 
            color: $color-intro;
        }
    }

    &__button {
        @extend %button;
        color: $color-white;
        border-color: $color-third!important;
        margin: 65px 0 0!important;

        svg {
            fill: $color-third;
        }

        &:hover, &:focus {
            color: $color-dark;

            &:before {
                background-color: $color-third;
            }

            svg {
                fill: $color-dark;
            }
        }
    }
}

//======================================================================================================
// Agenda
//======================================================================================================

.home-events {
    padding: 100px 0;

    &__top {
        @include flex($justifyContent: space-between);
    }

    &__header {
        @include flex($direction: column);
        gap: 5px;
    }

    &__title {
        @extend %titleHome;
    }

    &__subtitle {
        @extend %subTitle;
    }

    &__navigation {
        display: flex;
        gap: 10px;
        margin: 30px 0 0;

        &--prev, &--next {
            @extend %navigation;

            &:hover, &:focus {
                background-color: $color-second;
        
                svg {
                    fill: $color-white;
                }
            }
        }

        &.disable {
            display: none;
        }
    }

    &__swiper {
        overflow: visible!important;
        min-height: 550px;

        &:before {
            @include position(absolute, $top: 0, $left: -500px);
            content: " ";
            @include size(500px, 100%);
            background-color: $color-white;
            z-index: 2;
        }
    }

    &__link {
        @include size(300px!important, 500px!important);
        transition: margin $duration ease-in-out!important;
        position: relative;

        &:nth-child(odd) {
            margin-top: 42px;
        }

        &:hover, &:focus {
            .home-events {
                &__date {
                    &:before {
                        background-color: $color-third;
                    }
    
                    &:after {
                        width: 50px;
                        background-color: $color-third;
                    }
                }

                &__image img {
                    transform: scale(1.05);
                }
            }
        }
    }

    .swiper-slide-active {
        margin-top: 42px!important
    }

    &__image {
        @include size(100%, 430px);
        overflow: hidden;
        margin: 0;
        position: relative;

        &:before {
            @include position(absolute, $top: 0, $left: 0);
            @include size(100%);
            content: "";
            background: linear-gradient(180deg, rgba(22, 22, 22, 0) 0%, rgba(22, 22, 22, 0.7) 100%);
            z-index: 1;
        }

        img {
            @include size(100%);
            object-fit: cover;
            transform: scale(1);
            transition: all $duration ease-in-out;
        }

        &--notimg {
            background: url('/wp-content/themes/noyau/assets/images/icon-events.png') no-repeat $color-gray;
            background-position: center;
        }
    }

    &__date {
        @include flex($wrap: wrap);
        @include position(absolute, $left: 0, $bottom: 70px);
        max-width: 255px;
        width: fit-content;
        font-family: $font-family--heading;
        font-size: toRem(20);
        font-weight: 400;
        line-height: toRem(24);
        column-gap: 5px;
        color: $color-dark;
        background-color: $color-white;
        padding: 15px 30px 3px 0px;
        box-sizing: border-box;
        z-index: 1;

        &:before {
            @include position(absolute, $top: -15px, $right: -10px);
            @include size(20px, 30px);
            content: "";
            background-color: $color-main;
            transition: all $duration ease-in-out;
        }

        &:after {
            @include position(absolute, $left: 0, $bottom: -10px);
            @include size(25px, 3px);
            content: "";
            background-color: $color-main;
            transition: all $duration ease-in-out;
        }
    }

    &__title--small {
        font-family: $font-family--heading;
        font-size: toRem(26);
        font-weight: 700;
        line-height: toRem(32);
        color: $color-dark;
        margin: 17px 0 0;
        padding: 0;
    }
   
    &__button {
        @extend %button;
        color: $color-dark;
        border-color: $color-main!important;
        margin: 43px 0 0!important;

        svg {
            fill: $color-main;
        }

        &:hover, &:focus {
            color: $color-white;

            &:before {
                background-color: $color-main;
            }

            svg {
                fill: $color-white;
            }
        }
    }
}

//======================================================================================================
// Social Wall
//======================================================================================================

.social-wall {
    position: relative;
    margin: 0 0 190px;
    padding: 93px 0 135px;

    &:before {
        content: "";
        z-index: -1;
        @include absolute($top: 0, $right: 0, $bottom: 0, $left: 0);;
        margin: auto;
        background-color: $color-light;
    }

    &__top {
        @include flex($justifyContent: space-between, $alignItems: last baseline);
    }

    &__header {
        @include flex($direction: column);
        gap: 5px;
    }

    &__title {
        @extend %titleHome;
    }

    &__subtitle {
        @extend %subTitle;
    }

    &__hashtag {
        font-family: "Allura", sans-serif;
        font-size: toRem(50);
        font-weight: 400;
        line-height: toRem(63);
        color: $color-main;
    }

    &__wrapper {
        margin: 25px 0 0;
    }

    #ff-stream-1 {
        padding: 0;
        max-height: 355px!important;
        min-height: 355px!important;
        height: 355px!important;
        background: transparent!important;

        .ff-header, .ff-nickname, .ff-timestamp, .ff-loadmore-wrapper {
            display: none;
        }

        .picture-item__inner {
            background: transparent!important;
            box-shadow: 0 0 0 0 rgba(0,0,0,0)!important;

            .ff-item-cont {
                height: 355px;
            }
        }

        .ff-upic-round .ff-img-holder:first-child {
            border-radius: 0 !important;
        }

        .ff-stream-wrapper {
            display: flex;
            margin: 0;
            padding: 0;
            height: 355px!important;

            .ff-item {
                width: 270px!important;
                box-shadow: 0px 0px 25px 0px rgba(27, 35, 54, 0.1);
                transition: box-shadow $duration ease-in-out!important;
                
                &:nth-child(1) {
                    transform: unset!important;
                }

                &:nth-child(2) {
                    transform: translateX(300px)!important;
                }

                &:nth-child(3) {
                    transform: translateX(600px)!important;
                }

                &:nth-child(4) {
                    transform: translateX(900px)!important;
                }

                &:hover {
                    box-shadow: 0 0 0 0 rgba(0,0,0,0);

                    .ff-content::before {
                        background-color: $color-third;
                        width: 45px;
                    }
                }
            }
        }

        .ff-img-holder {
            @include size(100%!important, 180px!important);

            img {
                height: 100%;
                object-fit: cover;
            }
        }

        .ff-content {
            background-color: $color-white;
            height: 175px!important;
            padding: 25px 20px 75px;
            margin: 0;
            font-family: $font-family;
            font-size: toRem(14);
            font-weight: 400;
            line-height: toRem(20);
            position: relative;

            &:before {
                @include position(absolute, $bottom: 53px, $left: 20px);
                @include size(30px, 3px);
                content: "";
                background-color: $color-main;
                transition: all $duration ease-in-out;
            }
        }

        .ff-item-bar {
            @include position(absolute, $top: 305px);
            border: 0;
            width: 100%;
            padding: 15px 20px;
            z-index: 2;
            background-color: $color-white;
        }

        .ff-item-meta {
            width: 100%;
            @include position(absolute, $top: 168px, $left: 20px);
            margin: 0;
            height: 20px;
            z-index: 999;
            overflow: hidden;

            h6 {
                @include flex($alignItems: center);
                width: fit-content;
                background-color: $color-third;
                height: 22px;
                padding: 0 10px 0 22px;
                position: relative;

                &:before {
                    @include position(absolute, $top: 0, $left: 0);
                    @include size(20px);
                    content: "";
                    background-image: url(/wp-content/themes/savigny-sur-orge/assets/src/images/sprite-images/Pictos/ic_facebook.svg);
                    background-repeat: no-repeat;
                    background-size: 21px;
                }

                a {
                    font-family: $font-family;
                    font-size: toRem(11)!important;
                    font-weight: 600;
                    line-height: toRem(20);
                    text-transform: uppercase;
                }
            }
        }
    }

    &__buttons {
        @include flex($alignItems: center, $justifyContent: flex-end);
        gap: 20px;
    }

    &__button {
        @extend %button;
        color: $color-dark;
        border-color: $color-main!important;
        margin: 50px 0 0!important;
        padding: 0!important;
        gap: 5px;

        &:before {
            left: 20px;
        }

        svg {
            @include size(26px!important);
            fill: $color-dark;
        }

        &:first-child {
            width: 293px;
        }

        &:last-child {
            width: 297px;
        }

        &:hover, &:focus {
            color: $color-white;

            &:before {
                background-color: $color-main;
            }

            svg {
                fill: $color-white;
            }
        }
    }

    .newsletter-banner {
        position: relative;
        @include flex($direction: row, $wrap: wrap, $alignItems: center, $justifyContent: flex-start);
        background-color: $color-white;
        box-shadow: 0px 0px 1px 0px rgba(27, 35, 54, 0.08), 0px 10px 50px 0px rgba(27, 35, 54, 0.10);
        margin-top: 70px;
        margin-bottom: -200px;
        padding: 0 40px 0 25px;

        &:before, &:after {
            content: "";
            position: absolute;
            z-index: -1;
            @include size(25px, 35px);
            background-color: $color-main;
        }

        &:before {
            top: -10px;
            left: -10px;
        }

        &:after {
            right: -10px;
            bottom: -10px;
        }

        &__content {
            @include flex($direction: column, $alignItems: flex-start, $justifyContent: flex-start);
            margin: 17px 0 30px;
        }

        &__svg {
            @include size(60px);
            margin-right: 25px;
        }

        &__title {
            font-family: $font-family--title;
            font-size: toRem(45);
            line-height: toRem(65);
            font-weight: $font-weight;
            color: $color-second;
            margin: 0;
            padding: 0;
        }

        &__text {
            font-size: toRem(16);
            line-height: toRem(22);
            color: $color-text;
        }

        &__button {
            position: relative;
            z-index: 0;
            @extend %button;
            color: $color-dark;
            border-color: $color-main!important;
            padding: 0 30px 0 0!important;
            justify-content: flex-start;
            margin: 0 0 0 auto!important;
    
            svg {
                fill: $color-main;
            }
    
            &:hover, &:focus {
                color: $color-white;
    
                &:before {
                    background-color: $color-main;
                }
    
                svg {
                    fill: $color-white;
                }
            }
        }
    }
}

//======================================================================================================
// Kiosque
//======================================================================================================

.home-documents {
    position: relative;

    &:before {
        @include position(absolute, $top: 0, $left: calc(50% - 1015px));
        @include size(900px, 400px);
        content: "";
        background-color: $color-second;
        z-index: -1;
    }

    &__container {
        @include flex($justifyContent: space-between);
    }

    &__left {
        @include flex($direction: column);
        width: 270px;
        margin: 105px 0 0;

        .home-documents__navigation {
            display: none;
        }
    }

    &__header {
        @include flex($direction: column);
        gap: 5px;
    }

    &__title {
        @extend %titleHome;
        color: $color-white;
    }

    &__subtitle {
        @extend %subTitle;
        color: $color-white;

        span {
            background-color: $color-white;
        }
    }

    &__text {
        font-size: toRem(16);
        font-weight: 400;
        line-height: toRem(20);
        color: $color-intro;
        margin: 20px 0 148px;
    }

    &__more {
        @extend %button;
        color: $color-dark;
        border-color: $color-main!important;
        margin: 0!important;
        padding: 0!important;
        justify-content: flex-start;

        svg {
            fill: $color-main;
        }

        &:hover, &:focus {
            color: $color-white;

            &:before {
                background-color: $color-main;
            }

            svg {
                fill: $color-white;
            }
        }
    }

    &__navigation {
        @include flex($justifyContent: flex-end);
        gap: 10px;
        margin: 20px 0 35px;

        &--prev, &--next {
            @extend %navigation;

            &:hover, &:focus {
                background-color: $color-second;
        
                svg {
                    fill: $color-white;
                }
            }
        }

        &.disable {
            display: none;
        }
    }
    
    &__right {
        width: 815px;
        position: relative;

        &:before {
            @include position(absolute, $top: 80px, $left: -13px);
            @include size(25px, 35px);
            content: "";
            background-color: $color-main;
        }
    }

    &__swiper {
        padding: 15px!important;
        margin: -15px!important;
    }

    &__item {
        @include flex($alignItems: center, $direction: column);
        gap: 15px;
    }

    &__image {
        box-shadow: 1px 1px 15px 0px rgba(27, 35, 54, 0.15);
        height: 350px;

        img {
            display: block;
            height: 100%;
        }
    }

    &__content {
        @include flex($direction: column, $alignItems: center);
        gap: 10px;
    }

    &__buttons {
        @include flex($alignItems: center);
        gap: 10px;
    }

    &__button {
        @include flex($alignItems: center, $justifyContent: center);
        @include size(40px);
        background-color: $color-third;
        border-radius: 50px;
        transition: background-color $duration ease-in-out;

        svg {
            @include size(30px);
            fill: $color-dark;
            transition: fill $duration ease-in-out;
        }

        &:hover, &:focus {
            background-color: $color-second;

            svg {
                fill: $color-white;
            }
        }
    }

    &__title--small {
        font-family: $font-family--heading;
        font-size: toRem(16);
        font-weight: 700;
        line-height: toRem(18);
        text-align: center;
        margin: 0;
    }
}

//======================================================================================================
// Grands projets
//======================================================================================================

.projects {
    background-color: $color-light;
    margin: 120px 0 0;
    padding: 80px 0 75px;

    &__header {
        @include flex($direction: column);
        gap: 5px;
    }

    &__title {
        @extend %titleHome;
    }

    &__subtitle {
        @extend %subTitle;
    }

    &__item {
        @include flex($direction: column, $alignItems: flex-end);
        margin: 40px 0 0;

        &:hover, &:focus {
            .projects {
                &__image img {
                    transform: scale(1.05);
                }

                &__title--small::before {
                    width: 50px;
                }
            }
        }
    }

    &__image {
        @include size(100%, 440px);
        overflow: hidden;

        img {
            @include size(100%);
            object-fit: cover;
            transform: scale(1);
            transition: all $duration ease-in-out;
        }
    }

    &__content {
        @include flex($direction: column);
        gap: 20px;
        background-color: $color-main;
        color: $color-white;
        max-width: 450px;
        padding: 40px;
        margin: -205px 85px 0 0;
        position: relative;

        &:before {
            @include position(absolute, $bottom: 27px, $right: -13px);
            @include size(25px, 35px);
            content: "";
            background-color: $color-third;
        }
    }

    &__title--small {
        font-family: $font-family--heading;
        font-size: toRem(25);
        font-weight: 700;
        line-height: toRem(30);
        margin: 0;
        max-width: 340px;
        position: relative;

        &:before {
            @include position(absolute, $left: 0, $bottom: -10px);
            content: "";
            @include size(40px, 3px);
            background-color: $color-third;
            transition: width $duration ease-in-out;
        }
    }

    &__description {
        font-size: toRem(16);
        font-weight: 400;
        line-height: toRem(22);
    }

    &__button {
        @extend %button;
        color: $color-dark;
        border-color: $color-main!important;
        padding: 0!important;
        justify-content: flex-start;
        width: 209px;
        margin: -5px 0 0 !important;

        svg {
            fill: $color-main;
        }

        &:hover, &:focus {
            color: $color-white;

            &:before {
                background-color: $color-main;
            }

            svg {
                fill: $color-white;
            }
        }
    }
}

//======================================================================================================
// Portraits
//======================================================================================================

.portraits {
    background-color: $color-second;
    padding: 100px 0 60px;

    &__container {
        @include flex($alignItems: flex-end, $direction: column);
    }

    &__top {
        width: 100%;
    }

    &__header {
        @include flex($direction: column);
        gap: 0px;
    }

    &__title {
        @extend %titleHome;
        color: $color-white;
    }

    &__subtitle {
        @extend %subTitle;
        color: $color-white;

        span {
            background-color: $color-white;
        }
    }

    &__navigation {
        display: none;
        gap: 10px;

        &--prev, &--next {
            @extend %navigation;
        }

        &.disable {
            display: none;
        }
    }

    &__items {
        width: 100%;
    }

    &__wrapper {
        @include flex($justifyContent: space-between);
    }

    &__item {
        @include flex($direction: column);
        gap: 15px;
        width: 320px;
        position: relative;

        &:before {
            position: absolute;
            @include size(25px, 35px);
            content: "";
            z-index: 1;
        }

        &:nth-child(1) {
            transform: translateY(42px);

            &::before {
                top: 430px;
                right: -10px;
                background-color: $color-main;
            }
            
            .portraits__title--small::before {
                background-color: $color-main;
            }
        }

        &:nth-child(2) {
            transform: translateY(102px);

            &::before {
                top: 30px;
                right: -10px;
                background-color: $color-third;
            }

            .portraits__title--small::before {
                background-color: $color-third;
            }
        }

        &:nth-child(3) {
            &::before {
                bottom: 387px;
                left: -13px;
                background-color: $color-main;
            }

            .portraits__title--small::before {
                    background-color: $color-main;
            }
        }

        &:hover, &:focus {
            .portraits {
                &__image img {
                    transform: scale(1.05);
                }

                &__title--small::before {
                    width: 40px;
                }
            }
        }
    }

    &__image {
        @include size(100%, 450px);
        overflow: hidden;
        
        img {
            @include size(100%);
            object-fit: cover;
            transform: scale(1);
            transition: all $duration ease-in-out;
        }
    }

    &__content {
        @include flex($direction: column);
        gap: 20px;
        max-width: 300px;
    }

    &__title--small {
        font-family: $font-family--title;
        font-size: toRem(28);
        font-weight: 400;
        line-height: toRem(30);
        color: $color-white;
        margin: 0;
        position: relative;

        &:before {
            @include position(absolute, $left: 0, $bottom: -10px);
            content: "";
            @include size(25px, 3px);
            transition: width $duration ease-in-out;
        }
    }

    &__description {
        color: $color-intro;
        font-size: toRem(14);
        font-weight: 400;
        line-height: toRem(20);
    }

    &__button {
        @extend %button;
        color: $color-white;
        border-color: $color-third!important;
        padding: 0!important;
        justify-content: flex-start;
        width: 222px;
        margin: 65px 0 0 !important;

        svg {
            fill: $color-third;
        }

        &:hover, &:focus {
            color: $color-dark;

            &:before {
                background-color: $color-third;
            }

            svg {
                fill: $color-dark;
            }
        }
    }
}

//======================================================================================================
// Retour en image
//======================================================================================================

.retour {
    margin: 110px 0;

    &__header {
        @include flex($direction: column);
        gap: 8px;
    }

    &__title {
        @extend %titleHome;
    }

    &__subtitle {
        @extend %subTitle;
        text-transform: none;
    }

    &__blocs {
        margin-top: 50px;
        @include flex($wrap: wrap);
        gap: 55px;

        &.aos {
            transform: unset!important;
            transition-duration: 0s!important;
        }
    }

    &__bloc {
        position: relative;

        &:nth-child(1) {
            @include size(340px, 190px);
        }

        &:nth-child(2) {
            @include size(450px, 350px);
            margin-top: -160px;
        }

        &:nth-child(3) {
            @include size(270px, 260px);
            margin-top: -70px;

            &:after {
                @include position(absolute, $top: 22px, $left: -13px);
                @include size(25px, 35px);
                content: "";
                background-color: $color-third;
            }
        }

        &:nth-child(4) {
            @include size(430px, 270px);

            &:after {
                @include position(absolute, $top: 20px, $right: -13px);
                @include size(25px, 35px);
                content: "";
                background-color: $color-third;
            }
        }

        &:nth-child(5) {
            @include size(285px, 315px);
        }

        &:nth-child(6) {
            @include size(345px, 315px);
        }
    }

    &__wrapper {
        @include size(100%);
        overflow: hidden;
        position: relative;
        display: flex;

        &--link {
            &:hover, &:focus {
                .retour__image img {
                    transform: scale(1.05);
                    cursor: pointer;
                }
            }
        }

        &:hover, &:focus {

            .retour {
                &__title--small {
                    transform: translate(-50%, 0);
                    opacity: 1;
                }
            }
        }

        &--video {
            &:hover, &:focus {
                cursor: pointer;
            }
        }
    }

    &__image {
        @include size(100%);

        img {
            @include size(100%);
            object-fit: cover;
            transform: scale(1);
            transition: all $duration ease-in-out;
        }

        &--video {
            svg {
                @include position(absolute, $top: 50%, $left: 50%);
                transform: translate(-50%, -50%);
                z-index: 1;
                transition: opacity $duration ease-in;
            }
        }
    }

    &__title--small {
        @include position(absolute, $bottom: 22px, $left: 50%);
        transform: translate(-50%, 85px);
        opacity: 0;
        font-weight: 400;
        font-size: toRem(16);
        line-height: toRem(22);
        background-color: rgba($color-main, 0.9);
        color: $color-white;
        max-width: calc(100% - 100px);
        padding: 8px 20px;
        text-align: center;
        z-index: 1;
        transition: all $duration ease-in-out;
    }

    .popup-area {
        @include flex($alignItems: center, $justifyContent: center);
        @include position(fixed, $top: 0, $left: 0);
        @include size(100%);
        z-index: 20;
        opacity: 0;
        visibility: hidden;
        background: $color-black--rgba;

        &.show-popup {
            visibility: visible;
            opacity: 1;
        }

        video {
            width: 100%;
        }

        .accordion {
            height: 0;
            color: $color-white;

            svg {
                fill: $color-white;
            }
        }

        .close-area-popup {
            @include flex($alignItems: center, $justifyContent: center);
            cursor: pointer;
            position: absolute;
            top: 20px;
            right: calc((100% - 1170px)/2);
            @include size(40px);
            background: $color-white;
            border-radius: 50px;

            &::before {
                content: '\f335';
                font: normal 30px/1 'dashicons';
            }
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .alu {
        &__item {
            @include size(940px!important, 385px!important);
        }
        
        .swiper-container--content {
            width: calc(100% - 20px);
        }

        &__content {
            left: -30px;
            bottom: 110px;
            padding: 30px;
        }

        &__title {
            font-size: toRem(20);
            line-height: toRem(25);
        }

        &__text {
            font-size: toRem(14);
            line-height: toRem(20);
        }

        &__pagination {
            bottom: 50px;
            left: 45px;
        }
    }

    .access {
        &__blocs {
            gap: 35px;
            padding: 23px 52px;
            margin: -30px auto 0;
        }
    
        &__bloc {
            width: 95px;
    
            p {
                font-size: toRem(14);
                line-height: toRem(17);
            }
    
            &:hover, &:focus {
                p:before {
                    height: 6px;
                }
            }
        }
    
        &__picto {
            @include size(80px);
        }
    
        &__exit {
            margin: 3px 0 0;
        }
    }

    .news {
        margin: 100px 0 65px;
    
        &:before {
            bottom: -65px;
            height: 590px;
        }

        &__header {
            gap: 0;
        }
    
        &__content {
            gap: 40px;
            margin-top: 40px;
        }
    
        &__items {
            gap: 20px;
        }
    
        &__link--small {
            width: 300px;
        }
    
        &__image {
            &--big {
                @include size(540px, 320px);
                min-width: 540px;
            }
    
            &--small {
                height: 170px;
    
                &:before {
                    @include size(270px, 15px);
                }
            }
        }
    
        .notimg {
            background: url('/wp-content/themes/noyau/assets/images/icon-post.png') no-repeat $color-gray;
            background-position: center;
    
            &--big {
                @include size(540px, 320px);
                min-width: 540px;
            }
    
            &--small {
                height: 170px;
                position: relative;
    
                &:before {
                    @include size(270px, 15px);
                }
            }
        }
    
        &__infos {
            &--big {
                padding: 45px 35px;
                margin-left: -45px;
    
                &:before {
                    right: 20px;
                }
            }
    
            &--small {
                margin: -5px 0 0;
            }
        }
    
        &__titlep {
            &--big {
                font-size: toRem(30);
                line-height: toRem(32);
            }
    
            &--small {
                font-size: toRem(18);
                line-height: toRem(22);
                max-width: 260px;
            }
        }
    
        &__intro--big {
            font-size: toRem(16);
            line-height: toRem(22); 
        }

        &__button {
            margin: 40px 0 0 !important;
        }
    }

    .home-events {
        &__header {
            gap: 0;
        }
    
        &__swiper {
            min-height: 520px;
            margin: 10px 0 0;
        }
    
        &__link {
            @include size(280px!important, 450px!important);
        }
    
        &__image {
            height: 400px;
        }

        &__date {
            bottom: 50px;

            &:after {
                bottom: -8px;
            }
        }
    }

    .social-wall {
        margin: 0 0 185px;
        padding: 105px 0 130px;
    
        &__header {
            gap: 0;
        }
    
        #ff-stream-1 {
            .ff-stream-wrapper {
                .ff-item {
                    width: 220px!important;

                    &:nth-child(2) {
                        transform: translateX(240px)!important;
                    }
    
                    &:nth-child(3) {
                        transform: translateX(480px)!important;
                    }
    
                    &:nth-child(4) {
                        transform: translateX(720px)!important;
                    }
                }
            }
    
            .ff-img-holder {
                height: 160px!important;
            }
    
            .ff-content {
                padding: 25px 15px 75px;
    
                &:before {
                    bottom: 35px;
                    left: 15px;
                }
            }
    
            .ff-item-bar {
                padding: 15px 15px 25px;
                top: 305px;
                height: 50px;
            }
    
            .ff-item-meta {
                top: 147px;
                left: 15px;
    
                h6 {
                    height: 22px;
                    padding: 0 10px 0 22px;
    
                    a {
                        font-size: toRem(10)!important;
                    }
                }
            }
        }

        .newsletter-banner {

            &__content {
                width: 335px;
                margin: 15px 0 30px;
            }
        }
    }

    .home-documents {
        &:before {
            left: calc(50% + -600px);
            @include size(530px, 325px);
        }
    
        &__left {
            width: 270px;
            margin: 77px 0 0;
        }
    
        &__header {
            gap: 0;
        }
    
        &__text {
            margin: 20px 0 115px;
        }
        
        &__right {
            width: 640px;
    
            &:before {
                top: 75px;
            }
        }
    
        &__image {
            height: 285px;
        }
    }    

    .projects {
        margin: 100px 0 0;
        padding: 90px 0 75px;

        &__container {
            position: relative;
        }
    
        &__header {
            gap: 0;
        }
    
        &__image {
            height: 360px;
        }
    
        &__content {
            margin: -158px 50px 0 0;
        }
    
        &__button {
            margin: 0!important;
            @include position(absolute, $bottom: 0);
        }
    }

    .portraits {
        padding: 107px 0 73px;
    
        &__item {
            width: 270px!important;
    
            &:nth-child(1) {
                transform: translateY(40px);
    
                &::before {
                    right: -12px;
                    top: 360px;
                }
            }
    
            &:nth-child(2) {
                transform: translateY(100px);
    
                &::before {
                    right: -12px;
                }
            }
    
            &:nth-child(3) {
                &::before {
                    bottom: unset;
                    top: 330px;
                }
            }
        }
    
        &__image {
            height: 380px;
        }
    
        &__content {
            max-width: 100%;
        }
    
        &__button {
            margin: 60px 0 0 !important;
        }
    }

    .retour {
        margin: 100px 0 90px;
    
        &__header {
            gap: 0;
        }
    
        &__blocs {
            margin-top: 35px;
            gap: 45px;
        }
    
        &__bloc {
            position: relative;
    
            &:nth-child(1) {
                @include size(270px, 150px);
            }
    
            &:nth-child(2) {
                @include size(365px, 280px);
                margin-top: -130px;
            }
    
            &:nth-child(3) {
                @include size(215px, 210px);
                margin-top: -60px;
            }
    
            &:nth-child(4) {
                @include size(340px, 215px);
    
                &:after {
                    top: 30px;
                }
            }
    
            &:nth-child(5) {
                @include size(230px, 255px);
            }
    
            &:nth-child(6) {
                @include size(280px, 255px);
            }
        }
    
        &__title--small {
            bottom: 15px;
            max-width: unset;
            min-width: calc(100% - 58px) !important;
            padding: 8px 15px;
        }
    
        .popup-area .close-area-popup {
            right: calc(50% - 470px);
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .alu {
        &__container {
            max-width: unset;
            padding: 0;
        }

        .swiper-container {
            &--media {
                .alu__item {
                    @include size(100%!important, 290px!important);
                }
            }

            &--content {
                margin: 0 auto;
                max-width: 90%;
                padding: 0 10px;

                .alu__item {
                    @include size(100%!important, fit-content!important);
                    max-width: 100%;
                    align-items: flex-end;
                }
            }
        }

        &--video {
            .alu {
                &__item {
                    @include flex($direction: column);
                }

                &__media {
                    width: 100vw;
                }

                &__content {
                    top: unset;
                    left: unset;
                    max-width: 88%;
                    margin: -20px auto 0;
                }
            }

            video {
                display: none;
            }

            img {
                display: block;
            }
        }

        &__content {
            @include position(relative, $left: 0, $bottom: unset, $top: 265px);
            max-width: 100%;
            width: 100%;
            height: fit-content;
        }

        &__pagination {
            bottom: unset;
            left: 0;
            top: 236px;
        }
    }

    .access {
        &__blocs {
            flex-wrap: wrap;
            gap: 10px 30px;
            padding: 20px 35px 25px;
            margin: 145px auto 0;
            width: 100%;
        }
    
        &__bloc {
            width: 80px;
            margin: 0 auto;
    
            p {
                font-size: toRem(12);
                line-height: toRem(14);
            }
    
            &:hover, &:focus {
                p:before {
                    height: 4px;
                }
            }
        }
    
        &__picto {
            @include size(70px);
        }
    }

    .news {
        margin: 70px 0 68px;
        
        &:before {
            height: calc(100% - 315px);
        }

        &__container {
            align-items: flex-start;
        }
    
        &__link {
            &--big {
                flex-direction: column;
                width: 100%
            }
    
            &--small {
                width: calc(50% - 10px);
            }
        }

        &__items {
            justify-content: flex-start;
            flex-wrap: wrap;
            gap: 35px 20px;
        }
    
        &__image {
            &--big {
                @include size(100%, 415px);
                min-width: unset;
            }

            &--small {
                height: 190px;

                &:before {
                    width: calc(100% - 40px);
                }
            }
        }
    
        .notimg {
            &--big {
                @include size(100%, 415px);
                min-width: unset;
            }

            &--small {
                height: 190px;

                &:before {
                    width: calc(100% - 40px);
                }
            }
        }
    
        &__infos {
            &--big {
                background-color: $color-main;
                padding: 45px 40px 40px;
                gap: 45px;
                width: calc(100% - 60px);
                margin: -80px auto 0;
            }
        }
    
        &__titlep {
            &--big {
                font-size: toRem(26);
                line-height: toRem(28);
            }
    
            &--small {
                max-width: calc(100% - 55px);
            }
        }
    
        &__button {
            margin: 50px 0 0!important;
            // margin: 90px 0 0 !important;
        }
    }

    .home-events {
        padding: 60px 0 80px;

        &__swiper {
            margin: 52px 0 0 !important;
            width: 100% !important;
        }

        &__link {
            width: 280px!important;

            &:nth-child(odd) {
                margin-top: 0;
            }

            &.swiper-slide-active {
                margin-top: 0!important
            }
        }
    }

    .social-wall {
        z-index: 1;
        margin: 0;
        padding: 80px 0 145px;

        &__container {
            position: relative;
        }

        &__hashtag {
            @include position(absolute, $bottom: 65px);
            font-size: toRem(45);
            line-height: toRem(56);
        }

        &__wrapper {
            margin: 40px 0 0;
        }
    
        #ff-stream-1 {
            height: 780px !important;
            max-height: 780px !important;
            min-height: 780px !important;

            .ff-stream-wrapper {
                .ff-item {
                    width: calc(50% - 10px)!important;

                    &:nth-child(2) {
                        transform: translateX(calc(100% + 20px))!important;
                    }
    
                    &:nth-child(3) {
                        transform: translate(0, 400px)!important;
                    }
    
                    &:nth-child(4) {
                        transform: translate(calc(100% + 20px), 400px)!important;
                    }
                }
            }
    
            .ff-img-holder {
                height: 200px!important;
            }
    
            .ff-content {
                &:before {
                    bottom: 50px;
                }
            }
    
            .ff-item-bar {
                top: 330px;
            }
    
            .ff-item-meta {
                top: 190px;
            }
        }

        &__buttons {
            justify-content: flex-start;
        }

        &__button {
            margin: 95px 0 0 !important;
        }

        .newsletter-banner {
            margin-top: 65px;
            margin-bottom: -215px;
        }
    }

    .home-documents {
        padding: 190px 0 0;

        &:before {
            z-index: -1;
            left: 0;
            @include size(100%, 465px);
        }

        &__container {
            gap: 20px;
        }
    
        &__left {
            margin: 0;

            .home-documents__navigation {
                display: flex;
                margin: 0 0 110px;
                justify-content: flex-start;
            }
        }
    
        &__text {
            margin: 20px 0 32px;
        }
        
        &__right {
            width: 230px;
            padding-right: 55px;
            box-sizing: content-box;

            .home-documents__navigation {
                display: none;
            }
    
            &:before {
                top: -15px;
            }
        }
        
        &__navigation {
            &--prev, &--next {
                &:hover, &:focus {
                    background-color: $color-main;
                }
            }
        }
    
        &__image {
            height: 330px;
        }

        &__content {
            align-items: flex-start;
        }

        &__title--small {
            text-align: start;
        }
    }

    .projects {
        margin: 90px 0 0;
        padding: 70px 0 53px;

        &__item {
            align-items: center;
        }
    
        &__image {
            height: 300px;
        }
    
        &__content {
            width: calc(100% - 60px);
            margin: -60px auto 0;
            max-width: unset;
            padding: 30px 30px 38px;
            gap: 30px;

            &:before {
                bottom: -18px;
                right: 38px;
            }
        }

        &__title--small {
            max-width: unset;

            &:before {
                bottom: -15px;
            }
        }
    
        &__button {
            margin: 37px 0 0!important;
            @include position(relative, $bottom: unset);
        }
    }

    .portraits {
        padding: 70px 0 90px;
        background-color: $color-white;
        position: relative;

        &:before {
            @include position(absolute, $top: 0, $left: 0);
            @include size(100%, 480px);
            content: "";
            background-color: $color-second;
        }

        &__container {
            align-items: flex-start;
        }

        &__top {
            @include flex($alignItems: center, $justifyContent: space-between);
            position: relative;
        }

        &__navigation {
            display: flex;
            margin: 0 0 20px;

            &--prev, &--next {
                &:hover, &:focus {
                    background-color: $color-main;

                    svg {
                        fill: $color-white;
                    }
                }
            }
        }

        &__items {
            margin: 40px 0 0 !important;
            @include size(100%!important, 635px);
            overflow: visible!important;
            min-height: 550px;
        }

        &__item {
            transition: margin $duration ease-in-out!important;
            width: 270px!important;

            &:nth-child(1) {
                transform: translateY(0);
            }
    
            &:nth-child(2) {
                transform: translateY(0);
            }

            &:nth-child(odd) {
                margin-top: 0px;
            }

            &.swiper-slide-active {
                margin-top: 0px!important
            }
        }

        &__title--small {
            color: $color-second;
        }

        &__description {
            color: $color-gray;
        }
    
        &__button {
            margin: 20px 0 0 !important;
            color: $color-dark;
            border-color: $color-main!important;

            svg {
                fill: $color-main;
            }

            &:hover, &:focus {
                color: $color-white;

                &:before {
                    background-color: $color-main!important;
                }
    
                svg {
                    fill: $color-white;
                }
            }
        }
    }

    .retour {
        display: none;
    }
}


// 800
@media screen and (max-width: 800px) {

    .social-wall {
        padding-bottom: 220px;

        .newsletter-banner {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding: 0 35px;
            margin-bottom: -380px;
            height: 340px;
    
            &__svg {
                margin: 0;
            }
    
            &__content {
                width: auto;
                margin: 10px 0 20px;
            }
    
            &__button {
                margin: 0!important;

                &:hover, &:focus {
                    background-color: $color-main;
                }
            }
        }
    }

    .home-documents {
        padding-top: 240px;
    }
}


// 640
@media screen and (max-width: $small) {
    .alu {
        .swiper-container {
            &--media {
                height: auto;
                // height: 300px;

                .alu__item {
                    @include size(100%!important, unset!important);
                }
            }

            &--content {
                height: 100%;

                .alu__item {
                    height: 100% !important;
                }
            }
        }

        &__content {
            top: unset;
            bottom: 15px;
            transform: translateY(100%) !important;
            padding: 20px;
        }

        .container--pagination {
            margin: 0;
            padding: 0;
        }

        &__pagination {
            left: 10px;
            top: unset;
            bottom: 30px;
        }

        &--video {

            .alu {
                &__item {
                    width: unset !important;
                }

                &__content {
                    bottom: unset;
                    top: unset;
                    transform: unset!important;
                }
            }

            video {
                display: none;
            }

            img {
                display: block;
            }
        }
    }

    .access {
        &__blocs {
            gap: 10px;
            padding: 20px 55px 25px;
            margin: 160px auto 0;
        }
    }

    .news {
        margin: 70px 0 60px;
        
        &:before {
            height: calc(100% - 205px);
        }
    
        &__link--small {
            width: 100%;
        }

        &__items {
            gap: 75px;
        }
    
        &__image {
            &--big {
                height: 190px;
            }

            &--small {
                &:before {
                    width: 100%;
                }
            }
        }
    
        .notimg {
            &--big {
                height: 190px;
            }

            &--small:before {
                width: 100%;
            }
        }
    
        &__infos {
            &--big {
                padding: 30px 20px;
                gap: 35px;
                width: calc(100% - 20px);
                margin: -15px auto 0;

                &:before {
                    right: -9px;
                    bottom: 12px;
                }
            }

            &--small {
                width: 100%;
            }
        }
    
        &__titlep--small {
            max-width: calc(100% - 30px);
        }
    
        &__button {
            margin: 45px 0 0!important;
            // margin: 63px 0 0 !important;
        }
    }

    .home-events {
        &__top {
            flex-direction: column;
        }

        &__swiper {
            margin: 27px 0 0!important;
        }

        &__button {
            margin: 45px 0 0 !important;
        }
    }

    .social-wall {
        padding: 80px 0 220px;

        &__hashtag {
            bottom: 135px;
        }
    
        #ff-stream-1 {
            height: 1580px !important;
            max-height: 1580px !important;
            min-height: 1580px !important;

            .ff-stream-wrapper {
                .ff-item {
                    width: 100%!important;

                    &:nth-child(2) {
                        transform: translateY(400px) !important;
                    }
    
                    &:nth-child(3) {
                        transform: translateY(800px) !important;
                    }
    
                    &:nth-child(4) {
                        transform: translateY(1200px) !important;
                    }
                }
            }
    
            .ff-content {
                padding: 25px 25px 75px;

                &:before {
                    left: 25px;
                }
            }

            .ff-item-meta {
                left: 25px;
            }

            .ff-item-bar {
                padding: 15px 25px 25px;
            }
        }

        &__buttons {
            flex-direction: column;
            align-items: flex-start;
            margin: 100px 0 0 !important;
        }

        &__button {
            margin: 0 !important;
        }
    }

    .home-documents {
        padding: 250px 0 60px;

        &:before {
            height: 575px;
        }

        &__container {
            flex-direction: column;
            gap: 30px;
            position: relative;
        }
    
        &__left {
            margin: 0;

            .home-documents__navigation {
                margin: 0;
            }
        }
    
        &__text {
            margin: 20px 0 25px;
        }

        &__more {
            @include position(absolute, $bottom: -90px);
            width: 270px;
        }
        
        &__right {
            padding-right: 0;
    
            &:before {
                top: -17px;
                left: unset;
                right: -12px;
            }
        }

        &__item {
            gap: 10px;
        }
    }

    .projects {
        padding: 70px 0 57px;

        &__header {
            gap: 10px;
        }

        &__item {
            margin: 20px 0 0;
        }
    
        &__image {
            height: 170px;
        }
    
        &__content {
            width: calc(100% - 20px);
            margin: -20px auto 0;
            padding: 25px 20px 40px;

            &:before {
                right: 22px;
            }
        }
    
        &__button {
            margin: 28px 0 0!important;
            left: 10px;
            z-index: 1;

            &:before {
                z-index: -1;
            }
        }
    }

    .portraits {
        &__top {
            flex-direction: column;
            align-items: flex-start;
            gap: 25px;
        }

        &__navigation {
            display: flex;
            margin: 0;
        }

        &__items {
            margin: 45px 0 0 !important;
        }
    
        &__button {
            margin: -20px 0 0 !important;
            z-index: 1;
        }
    }
}
