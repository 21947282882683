//=================================================================================================
// Home 
//=================================================================================================

// Usage : @extend %button;
%button {
    gap: 15px;
    height: 50px;
    border: 1px solid!important;
    background-color: transparent!important;
    padding: 15px 30px 15px 0!important;
    overflow: hidden;
    transition: all $duration ease;
    position: relative;

    &:before {
        @include position(absolute, $top: 25px, $left: 7px);
        @include size(0);
        content: "";
        z-index: -1;
        border-radius: 50%;
        transition: all $duration ease-out;
    }

    svg {
        @include size(36px!important, 18px!important);
        margin: 0 0 0 -1px;
        transition: all $duration ease-in-out;
    }

    &:hover, &:focus {
        &:before {
            top: -125px;
            left: -10px;
            @include size(320px);
            transition: all $duration ease-out;
        }
    }
}

// Usage : @extend %titleHome;
%titleHome {
    font-family: $font-family--title;
    font-size: toRem(70);
    font-weight: 400;
    line-height: toRem(65);
    color: $color-second;
    margin: 0!important;
    padding: 0!important;
}

// Usage : @extend %subTitle;
%subTitle {
    @include flex($alignItems: center);
    gap: 10px;
    font-family: $font-family--heading;
    font-size: toRem(30);
    font-weight: 400;
    line-height: toRem(36);
    color: $color-dark;
    margin: 0!important;
    padding: 0!important;
    text-transform: lowercase;

    span {
        @include size(2px, 20px);
        background-color: $color-second;
        margin: 5px 0 0;
    }
}

// Usage : @extend %navigation;
%navigation {
    @include flex($alignItems: center, $justifyContent: center);
    @include size(40px!important);
    @include position(relative!important, $top: unset!important, $left: unset!important, $bottom: unset!important, $right: unset!important);
    margin-top: 0!important;
    border-radius: 50%;
    transition: all $duration ease-in-out;
    cursor: pointer;
    z-index: 2!important;
    background-color: $color-third;
    
    &:after {
        display: none;
    }

    svg {
        @include size(30px);
        fill: $color-dark;
        transition: all $duration ease-in-out;
    }

    &.swiper-button-disabled {
        opacity: 0.3!important;
    }
}


//=================================================================================================
// Clearfix
//=================================================================================================

// Usage : @extend %clearfix;
%clearfix {
    *zoom: 1;

    &:before, &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }
}


//=================================================================================================
// Visually hide an element
//=================================================================================================

// Usage : @extend %visuallyhidden
%visuallyhidden {
    width: 1px;
    height: 1px;
    position: absolute;
    padding: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    margin: -1px;
    white-space: nowrap;
}


//=================================================================================================
// Centered block
//=================================================================================================

// Usage : @extend %center
%center {
    display: block;
    margin-left: auto;
    margin-right: auto; 
}


//=================================================================================================
// Centered container
//=================================================================================================

// Usage : @extend %container
%container {
    max-width: $large;
    padding: 0 ($gl-gutter * 0.5);
    margin: 0 auto;
}


//=================================================================================================
// Cacul for positionning element left or right container
//=================================================================================================

// Usage : @extend %SpaceFull-to-Container--left
%SpaceFull-to-Container--left {
    left: calc((100% - ($large - $gl-gutter))/2);
}

// Usage : @extend %SpaceFull-to-Container--right
%SpaceFull-to-Container--right {
    right: calc((100% - ($large - $gl-gutter))/2);
}


//=================================================================================================
//  External link
//=================================================================================================
// Usage : @extend %external_linkAfter
%external_linkAfter {
    &::after {
        content: "";
        display: inline-block;
        mask-image: url($urlShort + 'ic-nav-exit.svg');
        mask-size: contain;
        mask-repeat: no-repeat;
        background-color: $color-text;
        @include size(20px);
    }
}

// Usage : @extend %external_link
%external_link {
    &[target="_blank"] {
        @extend %external_linkAfter;
    }
}



//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    %titleHome {
        font-size: toRem(60);
        line-height: toRem(60);
    }

    %subTitle {
        font-size: toRem(28);
        line-height: toRem(35);
    }

    %container {
        max-width: $medium;
        padding: 0 ($gl-gutter-md * 0.5);
        margin: 0 auto;
    }

    %SpaceFull-to-Container--left {
        left: calc((100% - ($medium - $gl-gutter-md))/2);
    }

    %SpaceFull-to-Container--right {
        right: calc((100% - ($medium - $gl-gutter-md))/2);
    }
}


// 960
@media screen and (max-width: $medium) {

    %container {
        max-width: 90%;
        padding: 0 ($gl-gutter-sm * 0.5);
        margin: 0 auto;
    }

    %SpaceFull-to-Container--left {
        left: calc((100% - (90% - $gl-gutter-sm))/2);
    }

    %SpaceFull-to-Container--right {
        right: calc((100% - (90% - $gl-gutter-sm))/2);
    }
}


// 640
@media screen and (max-width: $small) {

    %container {
        padding: 0 ($gl-gutter-xs * 0.5);
        margin: 0 auto;
    }
    
    %SpaceFull-to-Container--left {
        left: calc((100% - (90% - $gl-gutter-xs))/2);
    }

    %SpaceFull-to-Container--right {
        right: calc((100% - (90% - $gl-gutter-xs))/2);
    }
}
