.card {

    &__container {
        // Events v1
        &--event-v1 {
            gap: $card-event-v1-gap;
            padding: $card--padding;
            min-height: 200px;

            .card__content {
                padding: $card-content--padding;
            }
        }
    }


    //===========================
    // Events
    //===========================
    &__date {
        display: flex;
        align-items: stretch;
        padding: 20px 0;
        background-color: $color-bg--date-v1;

        p {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 70px;
            color: $color--date;
            font-family: $font-family--heading;
            font-size: toRem(18);
            font-weight: 700;
            line-height: toRem(22);
            text-transform: uppercase;
        }

        &__many {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        svg {
            box-sizing: content-box;
            width: 26px;
            height: 26px;
            fill: $color--date;
            margin: 5px 0;
        }
    }

    //=================================================================================================
    // Image par défaut
    //=================================================================================================
    .image-wrapper {
        &.events,
        &.events_category {
            background-image: url($urlSpriteImage + '/events-icons/events.svg');
        }
    }


    //=================================================================================================
    // Squared cards appearance
    //=================================================================================================
    &--square {
        .card {

            //===========================
            // Events
            //===========================
            &__date {
                position: absolute;
                top: 20px;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 0;
                padding: 0 20px;
                background-color: $color-bg--date-v2;

                p {
                    height: 40px;
                    padding: 0;
                    width: fit-content;
                    color: $color--date;
                    flex-direction: row;
                    gap: 5px;
                }

                svg {
                    transform: rotate(-90deg);
                    box-sizing: inherit;
                    padding: 0;
                    fill: $color--date;
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================


// 960
@media screen and (max-width: $medium) {

    .card {
        &:not(.card--square):not(.card--category):not(.card--portraits) {

            .card__image-wrapper:not(.events) {
                display: none;
            }

            .card__image-wrapper.events {
                background: none;
                width: auto;

                img {
                    display: none;
                }
            }

            .card__date {
                position: relative;

                p {
                    width: 85px;
                }
            }

        }

        &__container--event-v1 {
            .card__date {
                padding: 20px 5px;
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .card  {
        &:not(.card--square):not(.card--category):not(.card--portraits) {

            .card__image-wrapper.events {
                background: none;
                width: 100%;
                height: 70px;
                margin: 0 0 20px 0;
            }

            .card__date {
                position: relative;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 100%;
                padding: 0;

                &__many {
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                }

                p {
                    width: auto;
                    padding: 15px;
                }

                svg {
                    transform: rotate(-90deg);
                }
            }
        }

        &__container--event-v1 {
            height: auto;
        }
    }

}
